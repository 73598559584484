
























































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { searchHelper } from '@/utils/search';
import { scrollToTop } from '@/utils/scroll';
import { includes, has, pull, cloneDeep, clone } from 'lodash';
import { db } from '@/firebase';

@Component
export default class LearningFeatureBuilder extends Vue {
  loading = true;
  exam: {
    name: string;
    class: string;
    ids: string[];
    questions: any;
    series: { [series: string]: number };
  } = {
    name: '',
    class: '',
    ids: [],
    questions: {},
    series: {},
  };

  examSaving = false;
  total = 0;
  page = 0;
  query = '';
  facets: any[] = [];
  hits: any = [];
  popover = false;
  pageCount = 0;
  finalizeVisible = false;

  facetActive: { [key: string]: boolean } = {};
  activeNames = [0, 1];

  inExam(id: string) {
    return this.exam.ids.includes(id);
  }

  addToExam(id: string, series: string | string[], question: any) {
    const cleaned = cloneDeep(question);
    delete cleaned.collection;
    delete cleaned.objectID;
    // delete cleaned._highlightResult;
    delete cleaned.id;

    const newExam = this.exam;
    if (Array.isArray(series)) {
      series = series[0];
    }
    if (!includes(this.exam.ids, id)) {
      this.exam.ids.push(id);
      Vue.set(this.exam.questions, id, cleaned);
      if (!has(this.exam.series, series)) {
        Vue.set(this.exam.series, series, 1);
      } else {
        Vue.set(this.exam.series, series, newExam.series[series] + 1);
      }
    }
  }

  removeFromExam(id: string, series: string | string[]) {
    const newExam = this.exam;

    if (Array.isArray(series)) {
      series = series[0];
    }
    if (includes(newExam.ids, id)) {
      pull(newExam.ids, id);
      Vue.delete(newExam.questions, id);
      newExam.series[series] -= 1;
      if (newExam.series[series] === 0) {
        Vue.delete(newExam.series, series);
      }
      Object.assign(this.exam, newExam);
    }
  }

  async saveExam() {
    this.examSaving = true;
    const exam = clone(this.exam);
    delete exam.questions;
    await db
      .collection('LearningFeatureExams')
      .doc()
      .set(exam)
      .then(() => {
        this.exam = {
          name: '',
          class: '',
          ids: [],
          questions: {},
          series: {},
        };
      });
    this.examSaving = false;
    this.popover = false;
    this.finalizeVisible = false;
  }

  @Watch('query')
  search() {
    if (this.query.length >= 3) {
      searchHelper.setQuery(this.query).search();
    }
  }

  toggleFacet(attribute: string, value: string) {
    if (attribute !== 'collection') {
      searchHelper.toggleFacetRefinement(attribute, value).search();
      this.facetActive[attribute + value] = !this.facetActive[attribute + value];
    }
  }

  pageChange(page: number) {
    if (page < 1) {
      return;
    }
    searchHelper.setPage(page - 1).search();
    scrollToTop();
  }

  reset() {
    this.loading = true;
    searchHelper.clearRefinements().search();
    searchHelper.toggleFacetRefinement('collection', 'LearningFeature').setQuery('').search();
    this.query = '';
    this.facetActive = {};
  }

  isHighlighted = (content: string) => content.indexOf('search-highlight') >= 0;

  async created() {
    searchHelper.on('result', ({ results }) => {
      this.facets = results.facets.filter((facet) => facet.name !== 'collection') || [];
      this.total = results.nbHits;
      this.pageCount = results.nbPages;
      this.hits = results.hits;
      this.loading = false;
    });

    await searchHelper.addFacetRefinement('collection', 'LearningFeature').setQuery('').search();

    this.loading = false;
  }

  destroyed() {
    searchHelper.removeAllListeners('result');
  }
}
